<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        After a reaction went to completion, the crude reaction mixture consisted of two compounds,
        A and B, which were isolated by extraction. After each compound was dried, their masses were
        found to be
        <number-value :value="massAInit" unit="\text{mg}" />
        of compound A and
        <number-value :value="massBInit" unit="\text{mg}" />
        of compound B. Both compounds were individually recrystallized and weighed again. After
        recrystallization, the mass of compound A was
        <number-value :value="massAFinal" unit="\text{mg}" />
        and the mass of compound B was
        <number-value :value="massBFinal" unit="\text{mg.}" />
        Calculate the percent recovery from recrystallization for both compounds.
      </p>

      <calculation-input
        v-model="inputs.pctA"
        prepend-text="$\text{a) Percent recovery for A}:$"
        append-text="$\%$"
        class="mb-3"
        :disabled="!allowEditing"
      />

      <calculation-input
        v-model="inputs.pctB"
        prepend-text="$\text{b) Percent recovery for B}:$"
        append-text="$\%$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import StembleLatex from '@/tasks/components/StembleLatex';
import NumberValue from '@/tasks/components/NumberValue.vue';

export default {
  name: 'ChemUCI51LBA1Q9',
  components: {
    NumberValue,
    CalculationInput,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        pctA: null,
        pctB: null,
      },
    };
  },
  computed: {
    massAInit() {
      return this.taskState.getValueBySymbol('massAInit').content;
    },
    massAFinal() {
      return this.taskState.getValueBySymbol('massAFinal').content;
    },
    massBInit() {
      return this.taskState.getValueBySymbol('massBInit').content;
    },
    massBFinal() {
      return this.taskState.getValueBySymbol('massBFinal').content;
    },
  },
};
</script>
